.reporter-home {
  min-height: 100vh;
  .reporter-card-container {
    width: 100%;
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .filters {
    display: flex;
    flex-direction: row;
    .reporter-search {
      float: left;
      width: 100%;
      flex-grow: 1;
    }
    .button-pair {
      float: right;
      display: flex;
      flex-direction: row;
      .toggle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-width: 30px;
        padding: 6px;
        cursor: pointer;
        background-color: white;
        color: $solid-gray;
        border: 1px solid #959595;
        box-sizing: border-box;
        border-radius: 5px;
      }
      .filter {
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-width: 80px;
        cursor: pointer;
        margin: 0px 5px;
        background-color: white;
        color: $solid-gray;
        border: 1px solid #959595;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }
  }
  .footer-container {
    .reporter-footer {
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: $desktop-max-width;
      background-color: white;
      box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
    }
  }
  .reporter-table-container {
    max-width: 100%;
    overflow-x: scroll;
    margin-top: 10px;
    .reporter-table-wrapper {
      font-family: $font-secondary;
      .reporter-table {
        .reporter-table-header-column {
          padding: 10px;
        }
        .reporter-table-header {
          color: #909090;
          margin: 12px 10px;
          cursor: pointer;
          border-bottom: 1px solid #d4d4d4;
        }

        .reporter-table-row {
          border-bottom: 1px solid #d4d4d4;
          padding: 10px 0px;
          .reporter-table-column {
            text-transform: capitalize;
            padding: 12px 10px;
          }
        }
      }
    }
  }
}
